:root {
  --plyr-color-main: #000000;
}

section.block-video.fullscreen {
  padding-top: 0px;
  padding-bottom: 0px;
  height: auto;
  overflow: hidden;
}

@media (min-width: 992px) {
  section.block-video.fullscreen {
    height: calc(100vh - 108px);
    overflow: hidden;
  }
  section.block-video.fullscreen .plyr {
    position: static;
  }
}
section.block-video.background {
  position: relative;
  width: 100%;
  height: 100vh;
}
section.block-video.background .video-wrapper {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}
section.block-video.background .video-wrapper video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
section.block-video.background .video-wrapper .overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.4) 50%);
  background-size: 3px 3px;
  z-index: 2;
}